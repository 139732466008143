import styled from 'styled-components';
import { Link } from 'react-router-dom';

export const Container = styled.div`
  width: auto;
  height: auto;
`;

export const Header = styled.div`
  width: 90%;
  margin-left: 2%;
  margin-top: 5px;
`;

export const AddPaymentButton = styled.button`
  min-width: 33px;
  min-height: 30px;
  border-radius: 50%;
  margin-left: 10px;
  float: right;
  font-size: 26px;
  background-color: #28a745;
  text-align: center;
  color: white;
  border: none !important;

  &:focus {
    border: none;
  }
`;

export const DeleteAndEditActions = styled.div`
  display: flex;
  align-self: center;
  margin-top: auto;
  flex-direction: column;

  margin: auto;
`;

export const CancelButton = styled.button`
  // height: 25px;
  border-radius: 8px;
  padding: 5px;
  padding-left: 10px;
  padding-right: 10px;
  color: white;
  align-self: center;
  margin-right: 0px;
  border: none;
  background: red;
  margin-left: 10px;
  display: flex;
  flex-direction: row;
  text-align: center;
  margin-bottom: 6px;
  width: 90%;

  :hover {
    filter: drop-shadow(0 0 4px rgba(0, 0, 0, 0.4));
    text-decoration: none;
    color: white;
  }
  div {
    margin: auto;
    text-align: center;
  }
`;

export const SeeButton = styled.button`
  height: 25px;
  border-radius: 8px;
  padding: 5px;
  padding-left: 10px;
  padding-right: 10px;
  color: white;
  align-self: center;
  margin-right: 0px;
  border: none;
  background: #28a745;
  margin-left: 10px;
  display: flex;
  flex-direction: row;
  text-align: center;
  margin-bottom: 6px;
  width: 90%;

  :hover {
    filter: drop-shadow(0 0 4px rgba(0, 0, 0, 0.4));
    text-decoration: none;
    color: white;
  }
  div {
    margin: auto;
    text-align: center;
  }
`;

export const Trash = styled.button`
  height: 30px;
  align-self: center;
  float: right;
  position: absolute;
  clear: both;
  right: 0;
  margin-right: 20px;
  border: none;
  background: white;
`;

export const Table = styled.div`
  padding-top: 10px;
  width: 110mm;
  text-align: center;
  font-size: 25px;
  margin-right: auto;
`;

export const Order = styled.div`
  padding-top: 5px;
  width: 110mm;
  text-align: center;
  font-size: 15px;
  margin-right: auto;
  padding-bottom: 15px;
  border-bottom: solid black 1px;
`;

export const Restaurant = styled.div`
  padding-top: 10px;
  width: 110mm;
  text-align: center;
  font-size: 20px;
  margin-right: auto;
  padding-bottom: 15px;
  border-bottom: solid black 1px;
`;

export const Body = styled.div`
  width: 110mm;
  clear: both;
`;

export const Phone = styled.div`
  text-align: left;
  padding-left: 5px;
  font-size: 15px;
  padding-top: 10px;
  float: left;
`;

export const Time = styled.div`
  text-align: right;
  padding-right: 5px;
  font-size: 15px;
  padding-top: 10px;
  float: right;
`;

export const Items = styled.div`
  margin-top: 20px;

  
`;

export const Item = styled.div`
height: auto;
  :last-child {
    border-bottom: none;
  }

  :last-child {
    border-bottom: none;
  }
`;

export const ItemName = styled.div`
  font-size: 14px;
`;

export const ComplementsTitle = styled.div`
  font-size: 16px;
`;

export const Complements = styled.div`
  font-size: 12px;
  padding-left: 15px;
`;

export const Obs = styled.div`
  font-size: 13px;
`;
