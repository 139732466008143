import React, { useState, useEffect, useCallback } from 'react';
import {
  Container,
  BillExplanation,
  OpenOrders,
  CloseOrders,
  PaymentTable,
} from './styles';
import EyeOrderBill from '../EyeOrderBill';
import api from '~/services/api';

export default function EyeOrdersBill({
  session,
  inicialDate,
  finalDate,
  setSessions,
  toggleEyeOpen,
  getSessions,
  getTablesAvailable,
  bills,
  handleUpdateBills,
  modalEyeOpen,
}) {
  const [pendingBills, setPendingBills] = useState([]);
  const [closedBills, setClosedBills] = useState([]);

  const getBills = useCallback(async () => {
    const response = await api.get(
      `restaurants/table-sessions-bills/${session.id}`
    );

    const pending = response.data.bills.filter(
      (bill) => bill.status !== 'finished'
    );
    setPendingBills(pending);

    const closed = response.data.bills.filter(
      (bill) => bill.status === 'finished'
    );

    setClosedBills(closed);
  }, [session.id]);

  useEffect(() => {
    if (modalEyeOpen) {
      getBills();
    }
  }, [getBills, modalEyeOpen]);

  // const getTables = useCallback(async (id) => {
  //   const response = await api.get('/restaurants/tables');
  //   const tables = response.data;
  //   const tablesOptions = [];

  //   tables.forEach((table) => {
  //     let status;
  //     if (table.status === 'available') {
  //       status = 'Disponível';
  //     } else {
  //       status = 'Em Uso';
  //     }

  //     tablesOptions.push({
  //       value: table.id,
  //       label: `${table.table_number.toString()} - ${status}`,
  //     });
  //   });

  //   setTables(tablesOptions);
  // }, []);

  // const [modalTransfer, setModalTransfer] = useState(false);
  // async function toggleTransfer() {
  //   await getTables();
  //   setModalTransfer(!modalTransfer);
  // }

  let length = 0;
  pendingBills.forEach((bill) => {
    if (parseFloat(bill.total_price) > 0) {
      length++;
    }
  });

  return (
    <Container>
      <OpenOrders>
        <h6>
          <strong>Comanda</strong>
        </h6>
        <PaymentTable borderless>
          <tbody>
            {pendingBills &&
              pendingBills.length > 0 &&
              pendingBills.map(
                (bill) =>
                  parseFloat(bill.total_price) > 0 && (
                    <EyeOrderBill
                      bill={bill}
                      session={session}
                      setSessions={setSessions}
                      inicialDate={inicialDate}
                      finalDate={finalDate}
                      toggleEyeOpen={toggleEyeOpen}
                      length={length}
                      getSessions={getSessions}
                      getTablesAvailable={getTablesAvailable}
                    />
                  )
              )}

            {pendingBills.length < 1 && <p>Mesa sem clientes no momento</p>}
          </tbody>
        </PaymentTable>
      </OpenOrders>
      <CloseOrders>
        <h6>
          <strong>Contas Individuais Fechadas</strong>
        </h6>

        <PaymentTable borderless>
          <tbody>
            {closedBills.length > 0 ? (
              closedBills.map((bill) => {
                const userName = bill.buyer
                  ? bill.buyer.phone
                  : bill.waiter.name;
                return (
                  <tr key={bill.id}>
                    <td>{userName}</td>
                    <td>R${bill.total_service_price}</td>
                  </tr>
                );
              })
            ) : (
              <p>Nenhuma conta fechada</p>
            )}
          </tbody>
        </PaymentTable>
      </CloseOrders>
    </Container>
  );
}
