import React, { useCallback, useEffect, useState } from 'react';
import { Modal, ModalBody, Row, Col, Container } from 'reactstrap';
import api from '~/services/api';
import formatCompleteDate from '~/services/formatCompleteDate';

import { Header, Bloco, Content, Value, Comment, HistoryTable } from './styles';

export default function BoxCard({
  name,
  total,
  type,
  color,
  comment,
  payments,
  hasModal,
}) {
  const validateUser = useCallback(async () => {
    try {
      await api.post('/restaurants/users/permission');
    } catch (error) {
      if (error.response.data.payload.user_access === 'pdv') {
        window.location.href = '/operation';
      }
    }
  }, []);

  const [modal, setModal] = useState(false);
  function toggle() {
    setModal(!modal);
  }

  useEffect(() => {
    validateUser();
  }, [validateUser]);
  return (
    <div>
      <Bloco color={color} onClick={() => hasModal && toggle()}>
        <Header>{name}</Header>
        <Content>
          {type === 'clients' && <Value>{total}</Value>}

          {type === 'rateCount' && <Value>{total}</Value>}

          {type === 'payments' && <Value>R${total}</Value>}

          <Comment color={color}>
            <span>{comment}</span>
          </Comment>
        </Content>
      </Bloco>

      <Modal isOpen={modal} toggle={toggle}>
        <ModalBody>
          <Row>
            <Col>
              <h4>{name}</h4>
            </Col>
          </Row>
          <Row>
            <Col>
              <HistoryTable>
                <thead>
                  <tr>
                    <th>Mesa</th>
                    <th>Nº</th>
                    <th>Valor</th>
                    <th>Data / Hora</th>
                  </tr>
                </thead>
                <tbody>
                  {payments?.map((payment) => (
                    <tr>
                      <td>
                        {payment.table_type === 'balcony'
                          ? 'Balcão'
                          : payment.table_type === 'delivery'
                          ? 'Delivery'
                          : payment.table_number}
                      </td>
                      <td>#{payment.table_session_id}</td>
                      <td>R${parseFloat(payment.payment_value).toFixed(2)}</td>
                      <td>{formatCompleteDate(payment.created_at)}</td>
                    </tr>
                  ))}
                </tbody>
              </HistoryTable>
            </Col>
          </Row>
        </ModalBody>
      </Modal>
    </div>
  );
}
