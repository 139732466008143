import React, { useState } from 'react';
import formatCompleteDate from '~/services/formatCompleteDate';

import { useAuth } from '~/context/AuthContext';

import {
  Container,
  Title,
  Filter,
  Restaurant,
  Body,
  Phone,
  Price,
  Footer,
} from './styles';

const PrintClosingBox = React.forwardRef(
  (
    {
      totalManualDeposits,
      totalAutomaticsDeposit,
      boxTotalValue,
      boxInitialValue,
      totalWithdrawalDeposits,
      boxFinalValue,
      paymentsManual,
      paymentsAutomatics,
      manualDeposits,
      manualWithdrawals,
      boxClosingTime,
      boxOpeningTime,
      userClose,
      userOpen,
    },
    ref
  ) => {
    const { user } = useAuth();
    const filtered = paymentsAutomatics?.filter(
      (item) => item.description === 'Dinheiro'
    );

    const filteredValue =
      filtered && filtered.length > 0 ? Number(filtered[0].value) : 0;

    const boxDifference = -(
      Number(boxInitialValue || 0) +
      filteredValue +
      Number(totalManualDeposits || 0) -
      Number(totalWithdrawalDeposits || 0) -
      Number(boxFinalValue) || 0
    );

    const finalValueTheory =
      Number(boxInitialValue || 0) +
      Number(totalManualDeposits || 0) -
      Number(totalWithdrawalDeposits || 0) +
      filteredValue;
    // const filteredReady = filtered ? filtered[0]?.value : 0;

    return (
      <Container ref={ref}>
        <Title>
          <strong>FECHAMENTO DE CAIXA</strong>
        </Title>

        <Filter>
          <div style={{ fontSize: 16 }}>
            <strong>Aberto em: </strong>
            <strong>
              {boxOpeningTime
                ? formatCompleteDate(boxOpeningTime)
                : formatCompleteDate(new Date())}
            </strong>
          </div>{' '}
          {boxClosingTime && (
            <div style={{ fontSize: 16 }}>
              <strong>Fechado em: </strong>

              <strong>
                {boxClosingTime
                  ? formatCompleteDate(boxClosingTime)
                  : formatCompleteDate(new Date())}
              </strong>
            </div>
          )}
        </Filter>

        <Restaurant>
          <strong>{user.name}</strong>
        </Restaurant>
        <Body>
          <Phone>
            <strong>Aberto por:</strong>
          </Phone>
          <Price>
            <strong>{userOpen || '-'}</strong>
          </Price>
        </Body>

        <Body>
          <Phone>
            <strong>Fechado por:</strong>
          </Phone>
          <Price>
            <strong>{userClose || '-'} </strong>
          </Price>
        </Body>

        <Body>
          <Phone>
            <strong>Abertura (Dinheiro)</strong>
          </Phone>
          <Price>
            <strong>{`R$ ${boxInitialValue || '0.00'} `}</strong>
          </Price>
        </Body>

        <Body>
          <Phone>
            <strong>Saídas manuais (Dinheiro)</strong>
          </Phone>
          <Price>
            <strong>{`R$ ${totalWithdrawalDeposits || '0.00'} `}</strong>
          </Price>
        </Body>

        {manualWithdrawals &&
          manualWithdrawals?.length > 0 &&
          manualWithdrawals.map((item) => (
            <Body>
              <Phone type="description">
                <strong>
                  {`${formatCompleteDate(item.createdAt)} - ${item.description
                    }`}
                </strong>
              </Phone>
              <Price type="description">
                <strong>{`R$ ${parseFloat(item.value).toFixed(2)}`}</strong>
              </Price>
            </Body>
          ))}

        <Body>
          <Phone>
            <strong>Entradas manuais (Dinheiro)</strong>
          </Phone>
          <Price>
            <strong>{`R$ ${totalManualDeposits || '0.00'}`}</strong>
          </Price>
        </Body>

        {manualDeposits &&
          manualDeposits?.length > 0 &&
          manualDeposits.map((item) => (
            <Body>
              <Phone type="description">
                <strong>
                  {`${formatCompleteDate(item.createdAt)} - ${item.description
                    }`}
                </strong>
              </Phone>
              <Price type="description">
                <strong>{`R$ ${parseFloat(item.value).toFixed(2)}`}</strong>
              </Price>
            </Body>
          ))}

        <Body>
          <Phone>
            <strong>Vendas (Dinheiro)</strong>
          </Phone>
          <Price>
            <strong>{`R$ ${(filtered && filtered.length > 0 && parseFloat(filtered[0]?.value).toFixed(2)) || '0.00'
              }`}</strong>
          </Price>
        </Body>

        <Restaurant />

        <Body>
          <Phone>
            <strong>Caixa (Dinheiro)</strong>
          </Phone>
          <Price>
            <strong>{`R$ ${finalValueTheory.toFixed(2)}`}</strong>
          </Price>
        </Body>

        <Body>
          <Phone>
            <strong>Caixa informado (Dinheiro)</strong>
          </Phone>
          <Price>
            <strong>
              {' '}
              {`R$ ${Number(boxFinalValue) > 0
                ? Number(boxFinalValue).toFixed(2)
                : '0.00'
                }`}
            </strong>
          </Price>
        </Body>

        <Body>
          <Phone>
            <strong>Diferenca de caixa</strong>
          </Phone>
          <Price>
            <strong>{`R$ ${boxDifference.toFixed(2) || '0.00'}`}</strong>
          </Price>
        </Body>

        <Restaurant />

        <Title>Caixa de vendas</Title>

        {/* <Body>
          <Phone>
            <strong>Entradas manuais</strong>
          </Phone>
          <Price>
            <strong>{` R$ ${totalManualDeposits || '0.00'}`}</strong>
          </Price>
        </Body> */}

        {paymentsAutomatics &&
          paymentsAutomatics.length > 0 &&
          paymentsAutomatics.map((item) => (
            <Body>
              <Phone>
                <strong>{item.description}</strong>
              </Phone>
              <Price>
                <strong>{`R$ ${parseFloat(item?.value).toFixed(2)}`}</strong>
              </Price>
            </Body>
          ))}

        <Restaurant />
        <Body>
          <Phone>
            <strong>Total do caixa</strong>
          </Phone>
          <Price>
            <strong>{`R$ ${totalAutomaticsDeposit || '0.00'}`}</strong>
          </Price>
        </Body>

        <br />
        <Footer>
          <strong>Desenvolvido por Takeat</strong>
        </Footer>
      </Container>
    );
  }
);

export default PrintClosingBox;
