import styled, { css } from 'styled-components';
import ReactSelect from 'react-select';
import { Link } from 'react-router-dom';
import { lighten } from 'polished';
import { Table } from 'reactstrap';
import Input from '~/components/Form/Input';

export const SelectStyled = styled(ReactSelect)`
  width: 75%;
  text-align: left;
`;
export const Container = styled.div`
  display: flex;
  height: 100%;
`;

export const BillsItemsArea = styled.div`
  width: 40%;
  height: 100%;
  border-right: 1px solid #33333330;
  padding-right: 20px;
  overflow-y: auto;
`;

export const PartialBillArea = styled.div`
  width: 30%;
  padding-right: 20px;
  padding-left: 20px;
  border-right: 1px solid #33333320;

  h3 {
    font-size: 20px;
    font-weight: bold;
  }
`;

export const PaymentArea = styled.div`
  width: 30%;
  padding-right: 20px;
  padding-left: 20px;
position: relative;
  h3 {
    font-size: 20px;
    font-weight: bold;
  }
`;

export const Header = styled.head`
  display: flex;
  justify-content: space-between;
  margin-top: 20px;

  h3 {
    font-size: 20px;
    font-weight: bold;
  }

  span {
    font-weight: bold;
    font-size: 20px;
  }
`;

export const PerfilArea = styled.div`
  svg {
    margin-right: 4px;
  }
`;

export const PrintButton = styled.button`
  padding: 7px 14px;
  border: solid 1px #ff2c3a;
  background-color: transparent;
  color: #333;
  border-radius: 7px;

  svg {
    color: #ff2c3a;
    margin-right: 4px;
  }
`;

export const DisplayNoneImprimir = styled.button`
  display: none;
`;

export const ButtonAddBill = styled.button`
  padding: 7px 14px;
  border: solid 1px #01afff;
  background-color: transparent;
  color: #01afff;
  border-radius: 7px;
  font-weight: bold;
  margin-left: 8px;
  width: 25%;

  ${(props) =>
    props.disabled &&
    css`
      cursor: not-allowed;
      border: 1px solid #c4c4c4;
      color: #c4c4c4;
    `}

  svg {
    color: #01afff;
    margin-right: 4px;

    ${(props) =>
      props.disabled &&
      css`
        color: #c4c4c4;
      `}
  }
`;

export const BackButton = styled.button`
  border: none;
  color: #ff2c3a;
  background-color: transparent;
`;

export const StyledTable = styled.table`
  width: 100%;
  margin-top: 20px;
  margin-bottom: 30px;
  tr {
    th:last-child {
      text-align: right;
    }
  }
  tbody {
    tr {
      td:last-child {
        text-align: right;
      }
    }
  }
`;

export const RescueButton = styled.button`
  width: 65%;
  border: none;
  border-radius: 7px;
  background-color: #01AFFF;
  color: #fff;
  padding: 5px 10px;
  height: 44px;
  transition: all 0.4s;
  cursor: ${(props) => props.disabled && 'default'};
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  line-height: 20px;
`;

export const RescueContainer = styled.div`
  width: 98%;
  margin-bottom: 10px;
  position: relative;
  display: flex;
  

  [class*='InputContainer'] {
    margin: 0;
    width: 35%;
    margin-right: 10px;
  }
`;

export const RescueDate = styled.div`
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  width: 65%;

  [class*='InputContainer'] {
    width: 55%;
  }
`;
export const FinalDiv = styled.div`

  margin-left: 2%;
  margin-top: 10px;
  display: flex;
  justify-content: space-between;
`;

export const Total = styled.div`
  display: flex;
  flex-direction: column;
  span {
    color: grey;
  }
`;

export const Paid = styled.div`
  right: 0;

  display: flex;
  flex-direction: column;
  span {
    color: grey;
  }
`;

export const AddPaymentForm = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: 1fr 1fr 1fr;
  gap: 5px;

  height: auto;
`;

export const PaymentMethodButton = styled.button`
  width: 100%;
  height: 40px;
  color: #333;
  border: none;
  border-radius: 7px;
  transition: all 0.5s;

  font-size: 12px;
  line-height: 14px;

  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;

  &:hover {
    border: 1px solid red;
  }

  &:disabled {
    cursor: not-allowed;
  }
`;
export const PaymentDiv = styled.div`

  padding-bottom: 20px;
  /* border-bottom: 1px solid #dee2e6; */

  margin-top: 10px;
  display: flex;
  flex-direction: column;
  position: relative;
`;

export const InformationDiv = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const AddPaymentInput = styled(Input)`
  width: 90px;
  padding: 5px;
  font-size: 15px;
  border-radius: 10px;
  border-color: green;
  border-effect: none;
`;

export const AddPaymentLink = styled.button`
  min-width: 33px;
  max-width: 33px;
  min-heigth: 30px;
  max-heigth: 30px;
  border-radius: 50%;
  margin-left: 10px;
  float: right;
  font-size: 18px;
  background-color: #28a745;
  text-align: center;
  color: white;
  border: none !important;

  &:focus {
    border: none;
  }
`;
export const DeletePaymentForm = styled(Link)`
  padding-bottom: 5px;
  color: red;
`;

export const InputButton = styled.div`
  right: 0;
  position: absolute;
  display: flex;
  flex-direction: row;
  text-align: center;
  justify-content: center;
  align-items: center;
  height: 40px;
`;

export const BlueButton = styled.button`
  border-radius: 5px;
  background-color: #3bd2c1;
  border: none;
  height: 38px;
  width: 149px;
  font-family: Poppins;
  font-weight: 700;
  font-size: 14px;
`;
export const PaymentTable = styled.table`
  width: 100%;
  margin-top: 10px;

  tr {
    font-size: 18px;
  }
`;

export const ButtonsArea = styled.div`
  display: flex;

  flex-direction: column;

  width: 100%;
  position: absolute;
  bottom: 0px;
  padding-right: 25px;

  padding-top: 20px;

  border-top: 1px solid #c4c4c4;
  background: #fff;

  div {
      display: flex;
  justify-content: space-between;
  width: 100%;
  }

  @media (max-width: 1300px) {
    bottom: 0px;
  }
`;

export const InnerArea = styled.div`
  overflow-y: scroll;
  overflow-x: hidden;
  height: 729px;
  max-height: 720px;
 
  padding: 0px 0px 220px;

  @media (max-width: 1281px) {
    padding: 20px 20px 420px;
    max-height: 584px;
    height: 584px;
    margin-bottom: 420px;
  }

  @media (min-width: 1282px) and (max-width: 1450px) {
    padding: 20px 20px 420px;
    max-height: 620px;
    height: 620px;
  }


  ${(props) =>
    props.browserZoomLevel > 100 &&
    css`
      padding: 20px 20px 720px;
    `}
`;