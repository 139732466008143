import React, { useState, useRef, useMemo } from 'react';
import { toast } from 'react-hot-toast';
import * as Yup from 'yup';
import { Row, Col, ModalBody } from 'reactstrap';
import { format, startOfDay, subHours } from 'date-fns';
import getValidationErrors from '~/services/getValidationErrors';
import RadioInput from '~/components/Form/RadioInput';
import { Header, StyledForm, Container } from './styles';
import api from '~/services/api';
import Input from '~/components/Form/Input';
import CheckboxInput from '~/components/Form/Checkbox';
import SaveButton from '~/components/Buttons/SaveButton';

function EditTicket({ coupom, toggleModalEditTicket, getTickets }) {
  const formRef = useRef(null);
  const [discountType, setDiscountType] = useState(coupom.discount_type);
  const [type, setType] = useState(
    coupom.discount_type === 'absolute' ? 'Desconto (R$)' : 'Desconto (%)'
  );
  const today = useMemo(() => {
    return format(startOfDay(subHours(new Date(), 3)), 'yyyy-MM-dd');
  }, []);

  function changeType() {
    type === 'Desconto (%)'
      ? setType('Desconto (R$)')
      : setType('Desconto (%)');
    discountType === 'percentage'
      ? setDiscountType('absolute')
      : setDiscountType('percentage');
  }

  const [expirationDate, setExpirationDate] = useState(
    coupom.limit_date === null
      ? null
      : format(startOfDay(new Date(coupom.limit_date)), 'yyyy-MM-dd')
  );

  const [active, setActive] = useState(coupom.is_active);
  const [isPublic, setIsPublic] = useState(coupom.is_public);
  const [limited, setLimited] = useState(coupom.buyer_limit_buy !== null);
  const [expires, setExpires] = useState(coupom.limit_date !== null);

  const [coupomLimit, setcoupomLimit] = useState(coupom.buyer_limit_buy);

  function changeActive() {
    setActive(!active);
  }

  function changeIsPublic() {
    setIsPublic(!isPublic);
  }

  function changeLimited() {
    setLimited(!limited);
  }

  function changeExpires() {
    setExpires(!expires);
    !expires ? setExpirationDate(`${today}T03:00:00`) : setExpirationDate(null);
  }

  let discount_initial_data = coupom.discount;

  if (discountType !== 'absolute') {
    discount_initial_data = parseFloat(discount_initial_data) * 100;
    discount_initial_data = discount_initial_data.toString();
  }
  const initialData = {
    name: coupom.name,
    title: coupom.title,
    description: coupom.description,
    code: coupom.code,
    discount: parseFloat(discount_initial_data).toFixed(2).toString(),
    minimum_price: coupom.minimum_price || '0.00',
    maximum_discount: coupom.maximum_discount,
    buyer_limit_buy: coupomLimit,
    limit_date: expirationDate,
    limit_amount: coupom.limit_amount,
    active: coupom.is_active,
    expiration: false,
    isPublic: coupom.is_public,
  };

  async function handleSubmit(data) {
    try {
      if (formRef?.current) {
        formRef.current.setErrors({});
      }
      const schema = Yup.object().shape({
        name: Yup.string().required('Nome obrigatório'),
        code: Yup.string().required('Código obrigatório'),
        discount: Yup.string().required('Desconto obrigatório'),
      });

      await schema.validate(data, {
        abortEarly: false,
      });

      let discount_value = data.discount;

      if (discountType !== 'absolute') {
        discount_value = parseFloat(data.discount.replace(',', '.')) / 100;
        discount_value = discount_value.toString();
      }

      let formattedPrice = 0;

      if (discountType !== 'absolute') {
        formattedPrice = parseFloat(discount_value.replace(',', '.')).toFixed(
          4
        );
      } else {
        formattedPrice = parseFloat(discount_value.replace(',', '.')).toFixed(
          2
        );
      }

      const dataToSend = {
        name: data.name,
        title: '',
        description: '',
        code: data.code.toUpperCase(),
        discount_type: discountType,
        discount: formattedPrice,
        limit_date: expirationDate,
        is_active: active,
        is_public: isPublic,
      };

      if (data.minimum_price !== '') {
        dataToSend.minimum_price = parseFloat(
          data.minimum_price.replace(',', '.')
        ).toFixed(2);
      }

      if (data.maximum_discount !== '') {
        dataToSend.maximum_discount = parseFloat(
          data.maximum_discount.replace(',', '.')
        ).toFixed(2);
      }

      if (data.limit_amount !== '') {
        dataToSend.limit_amount = data.limit_amount;
      }

      if (coupomLimit !== null && limited) {
        dataToSend.buyer_limit_buy = coupomLimit;
      }

      await api.put(`/restaurants/discount-coupons/${coupom.id}`, dataToSend);
      toast.success('Cupom atualizado com sucesso!');
      getTickets();
      toggleModalEditTicket();
    } catch (error) {
      if (error instanceof Yup.ValidationError) {
        const errors = getValidationErrors(error);
        if (formRef?.current) {
          formRef.current.setErrors(errors);
        }
      } else {
        toast.error('Falha ao atualizar cupom');
      }
    }
  }

  return (
    <ModalBody>
      <Header>
        <div>
          <h2>Editar cupom</h2>
        </div>
      </Header>

      <Container>
        <Row>
          <Col md="12 ml-auto mr-auto">
            <StyledForm
              ref={formRef}
              onSubmit={handleSubmit}
              initialData={initialData}
            >
              <Row>
                <Col md="6">
                  <Input
                    label="Nome"
                    name="name"
                    type="text"
                    placeholder="60% OFF"
                  />

                  <Input
                    label="Código"
                    name="code"
                    type="text"
                    placeholder="60OFF"
                  />
                  <RadioInput
                    label="Cupom em Porcentagem"
                    name="discount_type"
                    value="percentage"
                    defaultChecked={discountType === 'percentage'}
                    onChange={changeType}
                  />
                  <RadioInput
                    label="Cupom em Reais"
                    name="discount_type"
                    defaultChecked={discountType === 'absolute'}
                    value="absolute"
                    onChange={changeType}
                  />
                  <Input label={type} name="discount" type="text" />
                </Col>
                <Col md="6">
                  <Input
                    label="Valor Mínimo do Pedido"
                    name="minimum_price"
                    type="text"
                    placeholder=""
                  />
                  <Input
                    label="Desconto Máximo"
                    name="maximum_discount"
                    type="text"
                    placeholder=""
                  />

                  <Input
                    name="limit_amount"
                    type="text"
                    label="Limite de vezes que pode ser usado"
                  />
                </Col>
              </Row>

              <Row>
                <Col>
                  <CheckboxInput
                    label="Limitar vezes que o mesmo cliente pode usar o cupom"
                    name="userLimitTime"
                    onChange={changeLimited}
                    defaultChecked={coupomLimit}
                  />
                </Col>
              </Row>
              <Row>
                <Col>
                  {' '}
                  {limited && (
                    <Input
                      name="buyer_limit_buy"
                      type="text"
                      placeholder="Limite de vezes que o mesmo cliente pode usar o cupom"
                      onChange={(e) => setcoupomLimit(e.target.value)}
                    />
                  )}
                </Col>
              </Row>
              <Row>
                <Col>
                  <CheckboxInput
                    label="Definir data de expiração"
                    onChange={changeExpires}
                    name="expiration"
                    defaultChecked={expires}
                  />
                </Col>
              </Row>

              <Row>
                <Col>
                  {expires && (
                    <Input
                      id="date"
                      label="Expira em"
                      type="date"
                      name="finalDate"
                      onChange={(e) =>
                        setExpirationDate(`${e.target.value}T03:00:00`)
                      }
                      defaultValue={expirationDate}
                      className="data"
                      InputLabelProps={{
                        shrink: true,
                      }}
                    />
                  )}
                </Col>
              </Row>
              <Row>
                <Col>
                  <CheckboxInput
                    label="Ativo"
                    name="active"
                    defaultChecked={active}
                    onChange={changeActive}
                  />
                </Col>
              </Row>
              <Row>
                <Col>
                  <CheckboxInput
                    label="Aparecer na lista de cupons disponíveis"
                    name="isPublic"
                    defaultValue={isPublic}
                    onChange={changeIsPublic}
                  />
                </Col>
              </Row>
              <div style={{ float: 'right' }}>
                <SaveButton type="submit">Cadastrar</SaveButton>
              </div>
            </StyledForm>
          </Col>
        </Row>
      </Container>
    </ModalBody>
  );
}

export default EditTicket;
