import React from 'react';
import OrderCard from '../OrderCard';

import { Container } from './styles';

function OrdersNew({ baskets, setBaskets, kdsViewMode }) {
  return (
    <Container kdsViewMode={kdsViewMode}>
      {baskets?.length > 0 &&
        baskets
          ?.filter(
            (item) => item.status === 'pending' || item.status === 'doing'
          )
          .map((item) => (
            <OrderCard
              item={item}
              baskets={baskets}
              setBaskets={setBaskets}
              key={item.id}
            />
          ))}
    </Container>
  );
}

export default OrdersNew;
