import React, { useState } from 'react';

import { Form } from '@unform/web';
import { BiTransfer } from 'react-icons/bi';
import { format, addHours } from 'date-fns';
import {
  Modal,
  ModalBody,
  ModalFooter,
  Button,
  Row,
  Col,
  Container,
} from 'reactstrap';
import { toast } from 'react-hot-toast';
import { TiDelete } from 'react-icons/ti';
import {
  Item,
  NamePrice,
  ItemName,
  PriceItem,
  Complements,
  ReopenItemButton,
  CancelButton,
  CancelButtonArea,
  ButtonArea,
  ItemArea,
  OrderTime,
  ItemInfo,
} from './styles';
import api from '~/services/api';

import formatTime from '~/services/formatTime';
import SelectTable from '~/components/Form/SelectTable';
import { useOrder } from '~/context/orderContext';
import DeleteItemOrdersBill from '../DeleteItemOrdersBill';

export default function EyeOrderBillCashback({ rescue }) {
  return (
    <Item key={rescue.id} style={{ width: '100%', color: '#018D66' }}>
      <ItemArea>
        <ItemInfo>
          <strong>Resgate Cashback</strong>
          <OrderTime>{formatTime(rescue?.createdAt)}</OrderTime>
        </ItemInfo>
        <PriceItem>- R${rescue.cashback} </PriceItem>
      </ItemArea>
    </Item>
  );
}
