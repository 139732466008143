import styled from 'styled-components';
// import { Link } from 'react-router-dom';

import { Form } from '@unform/web';

export const Wrapper = styled.div`
  margin: 10px;
`;

export const Header = styled.div`
  margin: 10px 30px 50px 30px;
  display: flex;
  justify-content: space-between;
`;

export const Container = styled.div``;

export const StyledForm = styled(Form)``;
