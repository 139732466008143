import styled from 'styled-components';
import { Table } from 'reactstrap';

export const NavLinkSpan = styled.span`
  margin-right: 5px;
`;

export const SessionsTable = styled(Table)`
  width: 100%;
  align-items: center;
  border-collapse: separate;
  border-spacing: 0 20px;
  border-radius: 8px;
  padding: 20px;

  thead {
    border-bottom: 30px solid transparent;
    text-align: left;
    border-top: none;
    tr {
      th {
        color: #333;
        button {
          border: none;
          background-color: transparent;
          font-weight: bold;
          color: #333;
        }
      }

      th:last-child {
        text-align: right;
      }
    }
  }
  tbody {
    td {
      font-size: 16px;
      border-bottom: 1px solid #33333310;
      color: #333;
    }
    td:last-child {
      text-align: right;
    }

    tr:last-child {
      td {
        border-bottom: none;
      }
    }
  }
`;

export const Content = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(100px, 1fr));
  grid-template-rows: repeat(auto-fit, 1fr);
  grid-gap: 20px;
  justify-items: center;
  margin-top: 20px;

  @media (max-width: 1100px) {
    grid-template-columns: repeat(auto-fill, minmax(80px, 1fr));
  }
`;

export const Container = styled.div`
  width: 100%;
  height: 100%;
  padding: 0 20px 20px;
  overflow: scroll;
  /* filter: drop-shadow(0 0 4px rgba(0, 0, 0, 0.1)); */

  input {
    max-height: 45px;
    min-height: 45px;
    border-radius: 7px;
    border: 1px solid #33333320;
    padding: 7px 14px;
    color: #33333380;
    font-size: 14px;
  }
`;
