import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';

import * as serviceWorker from './serviceWorker';
// import "bootstrap/dist/css/bootstrap.css";
// import "assets/scss/paper-dashboard.scss?v=1.1.0";
import 'bootstrap/dist/css/bootstrap.min.css';
import './assets/css/black-dashboard-pro-react.css';
import './assets/css/nucleo-icons.css';
import './assets/css/demo.css';

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root')
);

serviceWorker.registerServiceWorker();
