import React, {
  createContext,
  useMemo,
  useContext,
  useCallback,
  useState,
  useEffect,
} from 'react';
import { toast } from 'react-hot-toast';
import { useAuth } from '../AuthContext';
import api from '~/services/api';

const VideosContext = createContext();

const VideosProvider = ({ children }) => {
  const { user } = useAuth();
  const [videos, setVideos] = useState([]);

  const getVideos = useCallback(async () => {
    try {
      const response = await api.get('/restaurants/videos');

      setVideos(response.data);
    } catch (err) {
      console.log('getVideos Error: ', err);
      // toast.error('Não foi possível carregar os videos');
    }
  }, []);

  const getVideoById = useCallback(
    (id) => {
      return videos.find((v) => v.id === id);
    },
    [videos]
  );

  useEffect(() => {
    getVideos();
  }, [getVideos]);

  const value = useMemo(
    () => ({
      getVideos,
      videos,
      setVideos,
      getVideoById,
    }),
    [getVideos, videos, setVideos, getVideoById]
  );
  return (
    <VideosContext.Provider value={value}>{children}</VideosContext.Provider>
  );
};

function useVideos() {
  const context = useContext(VideosContext);

  if (!context) {
    throw new Error('useVideos must be used within an VideosProvider');
  }

  return context;
}

export { useVideos, VideosProvider };
