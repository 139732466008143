import XLSX from 'xlsx-js-style';
import style from './sheetStyle';

import formatCompleteDate from '../formatCompleteDate';

function getBankEventsData(bank_name, events) {
  const headers = [
    [`Conciliação do Banco ${bank_name}`],
    ['Valor', 'Saldo', 'Tipo', 'Método', 'Referente a', 'Criado em', 'Criado por'],
  ];

  const typeMap = {
    transfer_funds: 'Transferência de fundos',
    cash_flow: 'Lançamento',
    cash_flow_earning: 'Conta a receber',
    cash_flow_not_earning: 'Conta a pagar',
    manual_edit: 'Conferência de saldo',
    undefined: '??',
  };

  const data = events.map((e) => {
    const value = e.operation === '+'
      ? parseFloat(e.value)
      : -parseFloat(e.value);

    return [
        value,
        parseFloat(e.balance),
        typeMap[e.type],
        e.payment_method ? e.payment_method.name : '-',
        e.due_date ? formatCompleteDate(e.due_date) : '-',
        formatCompleteDate(e.createdAt),
        e.user ? e.user.name : '-',
    ];
  });

  return [...headers, ...data];
}

// /restaurants/cash-flows/bank-accounts/events/:id
export function generateSheet(bank_name, events) {
  const rows = getBankEventsData(bank_name, events);

  const columns = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';

  const num_cols = 7;

  const workbook = XLSX.utils.book_new();
  const worksheet = XLSX.utils.aoa_to_sheet(rows);

  worksheet['!cols'] = new Array(num_cols);
  worksheet['!cols'].fill({ wch: 16 });
  worksheet['!cols'][2] = { wch: 24 };

  worksheet["!merges"] = [
    {
      s: { r: 0, c: 0 },
      e: { r: 0, c: num_cols - 1 },
    },
  ];

  const black = '000000';
  const white = 'ffffff';

  const orange = 'fb6340';
  const light_orange = 'fed8cf';
  const green = '2dce89';
  const light_green = 'cbf3e2';
  const dark1 = '525f7f';

  {
    worksheet['A1'].s = style()
      .Font(white, '', 'center')
      .Bg(black)
      .Get();
  }

  for (let j = 0; j < num_cols; j += 1) {
    const cell = worksheet[columns[j] + '2'];

    cell.s = style()
      .Font(black, '', 'center')
      .Borders('tblr', black)
      .Get();
  }

  for (let j = 0; j < num_cols; j += 1) {
    const cell = worksheet[columns[j] + '2'];

    cell.s = style()
      .Font(black, '', 'center')
      .Borders('tblr', black)
      .Get();
  }

  for (let i = 2; i < rows.length; i += 1) {
    for (let j = 0; j < num_cols; j += 1) {
      const cell = worksheet[columns[j] + (i + 1)];

      let s = style().Borders('lr', black);

      if (j === 4 || j === 5) {
        s = s.Font(black, '', 'right');
      } else if (typeof rows[i][j] === 'number') {
        s = s.Number();
      }

      if (events[i - 2].operation === '+') {
        s = s.Bg(light_green);
      } else {
        s = s.Bg(light_orange);
      }

      if (i === rows.length - 1) {
        s = s.Borders('blr', black);
      }

      cell.s = s.Get();
    }
  }

  XLSX.utils.book_append_sheet(workbook, worksheet, 'Conciliação Bancária');

  return XLSX.write(workbook, { type: 'buffer' });
}
