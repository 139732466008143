import styled from 'styled-components';

export const Print = styled.button`
  height: 30px;
  width: 30px;
  border-radius: 50%;
  align-self: center;
  float: right;
  position: absolute;
  clear: both;
  right: 0;
  margin-right: 10px;
  border: none;
  background: #f0b402;

  :hover {
    filter: drop-shadow(0 0 4px rgba(0, 0, 0, 0.2));
    border: solid grey 1px;
    border-radius: 50%;
  }
`;

export const DisplayNoneImprimir = styled.div`
  display: none;

  @media print {
    display: block;
  }
`;
