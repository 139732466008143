import React, { useMemo } from 'react';

import {
  Container,
  Table,
  Bill,
  Body,
  Phone,
  Items,
  Item,
  ItemName,
  Complements,
  PriceItem,
  NamePrice,
  PriceComplement,
  TotalDiv,
  TotalRow,
  TotalName,
  TotalPrice,
} from './styles';

import formatCompleteDate from '~/services/formatCompleteDate';

export default function EyeBillsIndividual({ item }) {
  const userName = item?.bill.buyer
    ? item?.bill?.buyer?.phone
    : item?.bill?.waiter?.name;
  const date = useMemo(() => formatCompleteDate(item?.bill?.updatedAt), [item]);

  function changeValue(value) {
    const newValue = value.replace('.', ',');

    return newValue;
  }

  return (
    <Container>
      <Table>
        <strong>MESA {item?.table?.table_number}</strong>
      </Table>
      <Bill>Comanda Individual</Bill>
      <Bill>Solicitado {date}</Bill>{' '}
      <Body>
        <Phone>
          <strong>{userName}</strong>
        </Phone>
      </Body>
      <Items>
        {item?.bill?.order_baskets?.map((basket) => (
          <>
            {basket?.orders?.map((order) => (
              <Item key={order.id}>
                <NamePrice>
                  <ItemName>
                    {order?.use_weight ? (
                      <strong>
                        {changeValue(order?.weight)}kg {order?.product?.name}
                      </strong>
                    ) : (
                      <strong>
                        {order?.amount}x {order?.product?.name}
                      </strong>
                    )}
                  </ItemName>
                  <PriceItem>R${order?.total_price}</PriceItem>
                </NamePrice>
                {order?.complement_categories?.map((category) => (
                  <>
                    {category?.order_complements?.map((complement) => (
                      <div>
                        <NamePrice>
                          <Complements>
                            - {complement?.amount}x{' '}
                            {complement?.complement?.name}{' '}
                          </Complements>
                          <PriceComplement>
                            R${complement?.complement?.price}
                          </PriceComplement>
                        </NamePrice>
                      </div>
                    ))}
                  </>
                ))}
              </Item>
            ))}
          </>
        ))}
        <Item>
          <NamePrice>
            <ItemName>Serviços (10% - Opcional)</ItemName>
            <PriceItem>
              R$
              {(
                item?.bill?.total_service_price - item?.bill?.total_price
              ).toFixed(2)}
            </PriceItem>
          </NamePrice>
        </Item>
      </Items>
      <TotalDiv>
        <TotalRow>
          <TotalName>
            <strong>TOTAL</strong>
          </TotalName>
          <TotalPrice>
            <strong>R${item?.bill?.total_service_price}</strong>
          </TotalPrice>
        </TotalRow>
      </TotalDiv>
    </Container>
  );
}
