import React, { useRef, useState, useMemo } from 'react';
import { toast } from 'react-hot-toast';
import * as Yup from 'yup';

import { Row, Col, ModalBody } from 'reactstrap';
import { format, startOfDay, subHours } from 'date-fns';
import { Header, Container, StyledForm } from './styles';

import api from '~/services/api';

import Input from '~/components/Form/Input';
import CheckboxInput from '~/components/Form/Checkbox';
import RadioInput from '~/components/Form/RadioInput';
import SaveButton from '~/components/Buttons/SaveButton';
import getValidationErrors from '~/services/getValidationErrors';

function NewTicket({ getTickets, toggleAddTicketModal }) {
  const formRef = useRef(null);
  const [discountType, setDiscountType] = useState('percentage');
  const [type, setType] = useState('Desconto (%)');
  const today = useMemo(() => {
    return format(startOfDay(subHours(new Date(), 3)), 'yyyy-MM-dd');
  }, []);
  const [expirationDate, setExpirationDate] = useState(null);

  function changeType() {
    type === 'Desconto (%)'
      ? setType('Desconto (R$)')
      : setType('Desconto (%)');
    discountType === 'percentage'
      ? setDiscountType('absolute')
      : setDiscountType('percentage');
  }

  const [active, setActive] = useState(true);
  const [isPublic, setIsPublic] = useState(true);
  const [limited, setLimited] = useState(false);
  const [expires, setExpires] = useState(false);

  const [ticketLimit, setTicketLimit] = useState(null);

  function changeActive() {
    setActive(!active);
  }

  function changeIsPublic() {
    setIsPublic(!isPublic);
  }

  function changeLimited() {
    setLimited(!limited);
  }

  function changeExpires() {
    setExpires(!expires);
    !expires ? setExpirationDate(`${today}T03:00:00`) : setExpirationDate(null);
  }

  async function handleSubmit(data) {
    try {
      if (formRef?.current) {
        formRef.current.setErrors({});
      }

      const schema = Yup.object().shape({
        name: Yup.string().required('O nome é obrigatório'),
        discount: Yup.string().required('O desconto é obrigatório'),
        code: Yup.string().required('O código é obrigatório'),
      });

      await schema.validate(data, {
        abortEarly: false,
      });

      let discount_value = data.discount;

      if (discountType !== 'absolute') {
        discount_value = parseFloat(data.discount.replace(',', '.')) / 100;
        discount_value = discount_value.toString();
      }

      let formattedPrice = 0;

      if (discountType !== 'absolute') {
        formattedPrice = parseFloat(discount_value.replace(',', '.')).toFixed(
          4
        );
      } else {
        formattedPrice = parseFloat(discount_value.replace(',', '.')).toFixed(
          2
        );
      }

      const dataToSend = {
        currency: 'BRL',
        name: data.name,
        code: data.code,
        discount_type: discountType,
        discount: formattedPrice,
        limit_date: expirationDate,
        is_active: active,
        is_public: isPublic,
        description: '',
        title: '',
      };

      if (data.minimum_price !== '') {
        dataToSend.minimum_price = parseFloat(
          data.minimum_price.replace(',', '.')
        ).toFixed(2);
      }

      if (data.maximum_discount !== '') {
        dataToSend.maximum_discount = parseFloat(
          data.maximum_discount.replace(',', '.')
        ).toFixed(2);
      }

      if (data.limit_amount !== '') {
        dataToSend.limit_amount = data.limit_amount;
      }

      if (ticketLimit !== null) {
        dataToSend.buyer_limit_buy = ticketLimit;
      }

      await api.post('/restaurants/discount-coupons', dataToSend);

      toast.success('Cupom cadastrado com sucesso!');
      getTickets();
      toggleAddTicketModal();
    } catch (error) {
      if (error instanceof Yup.ValidationError) {
        const errors = getValidationErrors(error);
        if (formRef?.current) {
          formRef.current.setErrors(errors);
        }
      } else {
        // console.tron.log(error);
        toast.error(`Erro ao cadastrar cupom! ${error.message}`);
      }
    }
  }

  return (
    <ModalBody>
      <Header>
        <div>
          <h2>Cadastrar cupons</h2>
        </div>
      </Header>
      <Container>
        <Row>
          <Col md="12 ml-auto mr-auto">
            <StyledForm ref={formRef} onSubmit={handleSubmit}>
              <Row>
                <Col md="6">
                  <Input
                    label="Nome"
                    name="name"
                    type="text"
                    placeholder="60% OFF"
                  />

                  <Input
                    label="Código"
                    name="code"
                    type="text"
                    placeholder="60OFF"
                  />
                  <RadioInput
                    label="Cupom em Porcentagem"
                    name="discount_type"
                    value="percentage"
                    defaultChecked
                    onChange={changeType}
                  />
                  <RadioInput
                    label="Cupom em Reais"
                    name="discount_type"
                    value="absolute"
                    onChange={changeType}
                  />
                  <Input label={type} name="discount" type="text" />
                </Col>
                <Col md="6">
                  <Input
                    label="Valor Mínimo do Pedido"
                    name="minimum_price"
                    type="text"
                    placeholder=""
                  />
                  <Input
                    label="Desconto Máximo"
                    name="maximum_discount"
                    type="text"
                    placeholder=""
                  />

                  <Input
                    name="limit_amount"
                    type="text"
                    label="Limite de vezes que pode ser usado"
                  />
                </Col>
              </Row>

              <Row>
                <Col>
                  <CheckboxInput
                    label="Limitar vezes que o mesmo cliente pode usar o cupom"
                    name="userLimit"
                    onChange={changeLimited}
                  />
                </Col>
              </Row>
              <Row>
                <Col>
                  {' '}
                  {limited && (
                    <Input
                      name="buyer_limit_buy"
                      type="text"
                      placeholder="Limite de vezes que o mesmo cliente pode usar o cupom"
                      onChange={(e) => setTicketLimit(e.target.value)}
                    />
                  )}
                </Col>
              </Row>
              <Row>
                <Col>
                  <CheckboxInput
                    label="Definir data de expiração"
                    name="expiration"
                    onChange={changeExpires}
                  />
                </Col>
              </Row>

              <Row>
                <Col>
                  {expires && (
                    <Input
                      id="date"
                      label="Expira em"
                      type="date"
                      name="finalDate"
                      onChange={(e) =>
                        setExpirationDate(`${e.target.value}T03:00:00`)
                      }
                      defaultValue={today}
                      className="data"
                      InputLabelProps={{
                        shrink: true,
                      }}
                    />
                  )}
                </Col>
              </Row>
              <Row>
                <Col>
                  <CheckboxInput
                    label="Ativo"
                    name="active"
                    defaultChecked={active}
                    onChange={changeActive}
                  />
                </Col>
              </Row>
              <Row>
                <Col>
                  <CheckboxInput
                    label="Aparecer na lista de cupons disponíveis"
                    name="is_public"
                    defaultChecked={isPublic}
                    onChange={changeIsPublic}
                  />
                </Col>
              </Row>
              <div style={{ float: 'right' }}>
                <SaveButton type="submit">Cadastrar</SaveButton>
              </div>
            </StyledForm>
          </Col>
        </Row>
      </Container>
    </ModalBody>
  );
}

export default NewTicket;
