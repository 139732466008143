import React, { useState, useCallback, useEffect } from 'react';
import { Form } from '@unform/web';
import { toast } from 'react-hot-toast';

import { Modal, ModalBody, ModalFooter, Button, Row, Col } from 'reactstrap';
import Input from '~/components/Form/Input';
import api from '~/services/api';
import CheckboxInput from '~/components/Form/Checkbox';
import Select from '~/components/Form/SelectInput';
import { useAuth } from '~/context/AuthContext';
import NfeAvulsaItem from '../NfeAvulsaItem';
import { DownloadButton, Hyperlink } from 'ui-kit-takeat';
import NfeAvulsaVolume from '../NfeAvulsaVolume';

export default function NfeInutilizacao({
  modal,
  toggle,
  getNfces
}) {
  const { user } = useAuth();

  const [loadingNfe, setLoadingNfe] = useState(false);
  const [mensagemSefaz, setMensagemSefaz] = useState('');
  const [responseInutilizacao, setResponseInutilizacao] = useState(null);
  const [xmlLink, setXmlLink] = useState('');

 const handleInutilizacao = useCallback(async (data) => {
    try {
      setLoadingNfe(true);
      setMensagemSefaz('');
      setXmlLink('');

      const response = await api.post(
        `/restaurants/nfce/inutilizacao`,
        data
      );
      
      if(response.data.status_sefaz === '102'){
        setResponseInutilizacao(response.data);
        setMensagemSefaz(response.data.mensagem_sefaz);
        setXmlLink(`https://api.focusnfe.com.br${response.data.caminho_xml}`);
      }else{
        setResponseInutilizacao(response.data);
        setMensagemSefaz(response.data.mensagem_sefaz);
      }
      
      setLoadingNfe(false);
    } catch (err) {
      console.log(err);
      setLoadingNfe(false);
    }
  });

  return (
    <div>
      <Modal isOpen={modal} toggle={toggle} size="lg">
      <Form onSubmit={handleInutilizacao}>
        <ModalBody>
            <h4>Inutilização de notas</h4>
            <Row>
              <Col md="4">
                <Input
                  name="serie"
                  label="Série"
                  type="text"
                />
              </Col>
              <Col md="4">
                <Input
                  name="numero_inicial"
                  label="Número Inicial"
                  type="text"
                />
              </Col>
              <Col md="4">
                <Input
                  name="numero_final"
                  label="Número Final"
                  type="text"
                />
              </Col>
            </Row>
            <Row>
              <Col md="12">
                <Input
                  name="justificativa"
                  label="Justificativa"
                  type="text"
                />
              </Col>
            </Row>
          {mensagemSefaz !== '' && responseInutilizacao.status_sefaz !== '102' && (
            <Row style={{ marginTop: 10 }}>
              <h5 style={{ color: 'red' }}>
                <strong>Mensagem SEFAZ:</strong> {mensagemSefaz}
              </h5>
            </Row>
          )}

          {mensagemSefaz !== '' && responseInutilizacao.status_sefaz === '102' && (
            <Row style={{ marginTop: 10 }}>
              <p style={{ color: 'green' }}>
                {mensagemSefaz}
              </p>
              <p>Abrir XML: <a href={xmlLink} target='_blank' style={{color: 'blue'}}>clique aqui</a></p>
            </Row>
          )}
        </ModalBody>
        <ModalFooter>
          <Button color="danger" 
          onClick={() => {
            setMensagemSefaz('');
            setXmlLink('');
            setResponseInutilizacao(null);
            toggle();
          }
          }>
            Fechar
          </Button>
          <Button
            color="success"
            type="submit"
            disabled={loadingNfe}
          >
            {loadingNfe ? 'Aguarde' : 'Enviar'}
          </Button>
        </ModalFooter>
        </Form>
      </Modal>
    </div>
  );
}
