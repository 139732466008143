import React, { useEffect, useRef } from 'react';
import { useField } from '@unform/core';

import { RadioContainer } from './styles';

function RadioInput({ name, label, ...rest }) {
  const inputRef = useRef(null);
  const { fieldName, registerField, defaultValue, error, value } = useField(
    name
  );

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: inputRef.current,
      path: 'checked',
    });
  }, [fieldName, inputRef, registerField]);

  return (
    <RadioContainer>
      <input
        type="radio"
        id={fieldName}
        name={fieldName}
        ref={inputRef}
        value={value}
        defaultChecked={defaultValue}
        {...rest}
      />
      <label htmlFor={fieldName}>{label}</label>
      {error && <span>{error}</span>}
    </RadioContainer>
  );
}

export default RadioInput;
