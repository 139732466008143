import React, { useState, useRef, useEffect } from 'react';
import { useField } from '@unform/core';

import { MdInsertPhoto } from 'react-icons/md';
import { toast } from 'react-hot-toast';

import { Container, DefaultAvatar } from './styles';
import api from '~/services/api';

export default function CoverInput({
  name,
  deleteCover,
  ...rest
}) {
  const ref = useRef(null);
  const { fieldName, registerField, defaultValue, error } = useField(name);

  const [fileId, setFileId] = useState(defaultValue && defaultValue.id);
  const [preview, setPreview] = useState(defaultValue && defaultValue.url);

  useEffect(()=>{
    
      setPreview(null)
      setFileId(null)
    
  }, [deleteCover])

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: ref.current,
      path: 'dataset.fileid',
      clearValue() {
        if (ref.value) {
          ref.value = '';
        }
        setPreview(null);
      },
    });
  }, [fieldName, registerField]);

  useEffect(() => {
    if (defaultValue) {
      setPreview(defaultValue.url);
      setFileId(defaultValue.id);
    }
  }, [defaultValue]);

  async function handleChange(e) {
    try {
      const data = new FormData();

      data.append('file', e.target.files[0]);

      const response = await api.post('restaurants/files', data);
      const { id, url, name: nameData, path, updatedAt } = response.data;

      setFileId(id);
      setPreview(url);

 

      

        // newProductsArray[productFind] = {
        //   ...product,
        //   image: {
        //     url,
        //     id,
        //     name: nameData,
        //     path,
        //     updatedAt,
        //   },
        // };

     
      //   }
    } catch (err) {
      toast.error('Erro ao atualizar imagem');
      console.log(err);
    }
  }

  return (
    <Container>
      <label htmlFor="avatar">
        {preview ? (
          <img src={preview} alt="User Avatar" />
        ) : (
          <DefaultAvatar>
            <MdInsertPhoto size={45} color="#ddd" />
            <span>Adicionar Foto</span>
          </DefaultAvatar>
        )}

        <input
          type="file"
          id="avatar"
          accept="image/*"
          data-fileid={fileId}
          onChange={handleChange}
          ref={ref}
          {...rest}
        />
      </label>
    </Container>
  );
}
