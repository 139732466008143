import { differenceInDays, differenceInMinutes } from 'date-fns';
import React, { useEffect, useRef, useState } from 'react';

import {
  CategoryTitle,
  Container,
  Content,
  DateSpan,
  Empty,
  Header,
  MainContainer,
  MainNews,
  News,
  Old,
  Pending,
  Recent,
  ScrollableDiv,
  Tag,
  TagContainer,
} from './styles';
import parse from 'html-react-parser';
import zeroNotifications from '../../assets/img/zero_notifications.png';
import { FaTimes } from 'react-icons/fa';
import { Research } from '../Research';

export const NotificationPannel = ({ setNotificationsOpen, notifications }) => {
  const now = new Date();
  const [windowScroll, setWindowScroll] = useState(0);
  const recent = notifications.all?.filter(
    (n) => differenceInDays(now, new Date(n.createdAt)) <= 7
  );
  const old = notifications.all?.filter(
    (n) => differenceInDays(now, new Date(n.createdAt)) > 7
  );

  const fixeds = notifications.fixed;
  const ids = notifications.all
    ?.map((n) => n.id)
    .concat(notifications.fixed?.map((f) => f.id));
  const newsRead = JSON.parse(localStorage.getItem('@gddashboard:newsRead'));

  const tagColors = {
    new: '#FFF6E5',
    fix: '#E8F8FF',
    update: '#FFF0F2',
    conquer: '#9EEBFA',
  };

  const tagText = {
    new: 'Novidade',
    fix: 'Correção',
    update: 'Atualização',
    conquer: 'Conquista',
  };

  const months = [
    'janeiro',
    'fevereiro',
    'março',
    'abril',
    'maio',
    'junho',
    'julho',
    'agosto',
    'setembro',
    'outubro',
    'novembro',
    'dezembro',
  ];

  const getTimeDiff = (time) => {
    if (time < 60) {
      return time + ' minuto' + (time > 1 ? 's' : '');
    }
    if (time < 1440) {
      return ~~(time / 60) + ' hora' + (~~(time / 60) > 1 ? 's' : '');
    }
    return ~~(time / 1440) + ' dia' + (~~(time / 1440) > 1 ? 's' : '');
  };

  useEffect(() => {
    window.addEventListener('click', (e) => {
      setNotificationsOpen(false);
      localStorage.setItem('@gddashboard:newsRead', JSON.stringify(ids));
    });

    window.addEventListener('scroll', () => setWindowScroll(window.scrollY));
  }, []);

  //Redeploy

  return (
    <Container
      key="notifications"
      initial={{ x: 500 }}
      animate={{ x: 0 }}
      exit={{ x: 500 }}
      transition={{ type: 'spring', mass: 0.4 }}
      scroll={windowScroll}
      onClick={(e) => e.stopPropagation()}
    >
      <Header>
        <span>Notificações</span>
        <FaTimes
          onClick={() => {
            setNotificationsOpen(false);
            localStorage.setItem('@gddashboard:newsRead', JSON.stringify(ids));
          }}
        />
      </Header>

      <ScrollableDiv>
        {notifications.all.length + fixeds?.length <= 0 && (
          <Empty>
            <img src={zeroNotifications} />
            <h5>Nenhuma notificação ainda</h5>
            <p>Não há nenhuma notificação no momento por aqui.</p>
          </Empty>
        )}
        {fixeds.length > 0 && (
          <MainNews>
            <span>Principal</span>
            {fixeds.map((f) => (
              <MainContainer
                clickable={!!f.link}
                onClick={() => {
                  if (f.link) {
                    window.open(
                      f.link,
                      f.link.includes('dashboard.takeat.app')
                        ? '_self'
                        : '_blank'
                    );
                  }
                }}
              >
                {!newsRead?.includes(f.id) && (
                  <Pending
                    style={{ position: 'absolute', top: -5, right: 0 }}
                  />
                )}
                {f.file && (
                  <img
                    src={f.file.url}
                    style={f.category === 'conquer' ? { width: '98%' } : {}}
                  />
                )}
                <p>{f.category !== 'conquer' && parse(f.description)}</p>
                {f.research && !f.rated && (
                  <Research research={f.research} notif={f} />
                )}
              </MainContainer>
            ))}
          </MainNews>
        )}
        {recent.length > 0 && (
          <Recent>
            <CategoryTitle>Recentes</CategoryTitle>
            {recent.map((r) => {
              const now = new Date();
              const date = new Date(r.createdAt);
              const timePassed = differenceInMinutes(now, date);

              return (
                <News
                  key={r.id}
                  clickable={!!r.link}
                  onClick={() => {
                    if (r.link) {
                      window.open(
                        r.link,
                        r.link.includes('dashboard.takeat.app')
                          ? '_self'
                          : '_blank'
                      );
                    }
                  }}
                >
                  <TagContainer>
                    <Tag color={tagColors[r.category]}>
                      {tagText[r.category]}
                    </Tag>
                    <DateSpan>{`há ${getTimeDiff(timePassed)}`}</DateSpan>
                    {!newsRead?.includes(r.id) && <Pending />}
                  </TagContainer>
                  <Content>{parse(r.description)}</Content>
                </News>
              );
            })}
          </Recent>
        )}
        <Old>
          {old.length > 0 && <CategoryTitle>Antigos</CategoryTitle>}
          {old.map((o) => {
            const date = new Date(o.createdAt);
            return (
              <News
                key={o.id}
                clickable={!!o.link}
                onClick={() => {
                  if (o.link) {
                    window.open(
                      o.link,
                      o.link.includes('dashboard.takeat.app')
                        ? '_self'
                        : '_blank'
                    );
                  }
                }}
              >
                <TagContainer>
                  <Tag color={tagColors[o.category]}>{tagText[o.category]}</Tag>
                  <DateSpan>{`${date.getDate()} de ${
                    months[date.getMonth()]
                  }`}</DateSpan>
                  {!newsRead?.includes(o.id) && <Pending />}
                </TagContainer>
                <Content>{parse(o.description)}</Content>
              </News>
            );
          })}
        </Old>
      </ScrollableDiv>
    </Container>
  );
};
