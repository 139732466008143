import React from 'react';
import { Label, Hour } from './styles';

export const ProgressCircle = ({ label, idx, step, hour }) => {
  return (
    <div
      style={{
        width: '25px',
        height: '25px',
        borderRadius: '50%',
        border: step >= idx ? '3px solid #ff403d' : '3px solid #C0C4C2',
        position: 'relative',
        backgroundColor: step >= idx ? '#ff403d' : 'transparent',
      }}
    >
      <Label>
        <strong>{label}</strong>
        <p>{hour}</p>
      </Label>
    </div>
  );
};
