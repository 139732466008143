import styled, { css } from 'styled-components';

export const Container = styled.div`
  width: 100%;
  display: grid;
  

${(props) =>
  props.kdsViewMode === 3 &&
  css`
    grid-template-columns: repeat(3, 1fr);
  `}
 
 ${(props) =>
   props.kdsViewMode === 4 &&
   css`
     grid-template-columns: repeat(4, 1fr);
   `}

${(props) =>
  props.kdsViewMode === 5 &&
  css`
    grid-template-columns: repeat(5, 1fr);
  `}

  gap: 20px;
`;
